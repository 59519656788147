.nav-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: all 1s;
}
.nav-link:hover {
  color: white;
  text-decoration: none;
  background-color: slategray;
  border-radius: 5px;
  transition: all 1s;
}
.nav-link:active {
  color: white;
  text-decoration: none;
  border-bottom: royalblue 3px solid;
  transition: all 1s;
}
.nav-link-active {
  color: white;
  text-decoration: none;
  border-bottom: royalblue 3px solid;
  transition: all 1s;
}
